import { createStore } from 'vuex';
import axios from 'axios';
import * as localforage from 'localforage';
import VuexPersistence from 'vuex-persist';
import { global } from './modules/global.module';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
});

export default createStore({
  state: {
    Jobs: [],
    JobsPayload: {},
    plans: [],
    Enums: null,
    appSettings: null,
  },
  mutations: {
    setJobs(state, { data, links, meta }) {
      localforage.setItem('Jobs', [...data]);
      state.Jobs = [...data];
      //console.log(meta, links, 'HERE')
      state.JobsPayload = { links, meta };
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    setEnums(state, payload) {
      state.Enums = payload;
    },
    setAppSettings(state, payload) {
      state.appSettings = payload;
    },
  },
  getters: {
    getJob:
      ({ Jobs }) =>
      async (id) => {
        try {
          if (Array.isArray(Jobs) && Jobs.length > 0) {
            const job = Jobs.filter((job) => job.fid == id)[0] || null;
          }
          if (!job) {
            const jobs = await localforage.getItem('Jobs');
            return jobs.filter((job) => job.fid == id)[0] || null;
          }
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getJobs(state) {
      return state.Jobs;
    },
    getJobsPayload(state) {
      return state.JobsPayload;
    },
    getPlans(state) {
      return state.plans;
    },
    getEnums(state) {
      return state.Enums;
    },
    getSettings(state) {
      return state.appSettings;
    },
  },
  actions: {
    async getAllJobs({ commit }, credentials) {
      const res = await instance.get('misc/jobs', { params: credentials });
      commit('setJobs', res.data);
      return res.data;
    },
    async getSubscriptionPlans({ commit }) {
      const res = await instance.get('misc/subscription-plans');
      commit('setPlans', res.data.data);
      return res.data;
    },
    async getSingleJob({ commit }, credential) {
      const res = await instance.get(`misc/jobs/${credential}`);
      return res.data.data;
    },
    async getEnums({ commit }) {
      const res = await instance.get('misc/enums');
      commit('setEnums', res.data.data);
      return res.data.data;
    },
    async getSettings({ commit }) {
      const res = await instance.get('misc/app-settings');
      commit('setAppSettings', res.data.data);
      return res.data.data;
    },
    async getJobCategory({ commit }) {
      const res = await instance.get('misc/job-categories');
      return res.data.data;
    },
  },
  modules: { global },
  plugins: [vuexLocal.plugin],
});
